/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ lang, title, description, meta }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                        title
                        titleTemplate
                        description
                    }
                }
            }
        `
    )

    const seo = {
        url: site.siteMetadata.siteUrl,
        title: title || site.siteMetadata.title,
        titleTemplate: site.siteMetadata.titleTemplate,
        description: description || site.siteMetadata.description,
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={seo.title}
            titleTemplate={seo.titleTemplate}
            meta={[
                {
                    name: `description`,
                    content: seo.description,
                },
                {
                    property: `og:url`,
                    content: seo.url,
                },
                {
                    property: `og:title`,
                    content: seo.title,
                },
                {
                    property: `og:description`,
                    content: seo.description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:title`,
                    content: seo.title,
                },
                {
                    name: `twitter:description`,
                    content: seo.description,
                },
            ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    title: null,
    description: null,
    meta: [],
}

SEO.propTypes = {
    lang: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    meta: PropTypes.array,
}

export default SEO
